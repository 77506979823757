<template>
  <div>
    <h3>System Payments</h3>
    <loading v-if="loading" />
    <div class="card mt-3" v-if="! loading">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h4>Filter</h4>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date From</label>
              <date-picker input-class="form-control" v-model="filter.from" placeholder="Date From" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date To</label>
              <date-picker input-class="form-control" v-model="filter.to" placeholder="Date To" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Sort Order</label>
              <select v-model="filter.sort" class="form-control">
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Method</label>
              <select v-model="filter.method" class="form-control">
                <option value="all">all</option>
                <option value="mpesa">mpesa</option>
                <option value="ipay">ipay</option>
                <option value="pesapal">pesapal</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Search</label>
              <input
                  type="text"
                  class="form-control"
                  placeholder="Search Reference"
                  v-model="filter.term"
                />
            </div>
          </div>
          <div class="col-md-8 text-end">
            <button class="btn btn-primary" @click.prevent="fetch"><i class="lni lni-search me-2"></i>Filter</button>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Method</th>
              <th>Amount</th>
              <th>Reference</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="payments.length == 0">
              <td colspan="6">There is no data to display.</td>
            </tr>
            <tr v-for="(payment, i) in payments" :key="`payment-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ payment.method }}</td>
              <td>{{ payment.amount }}</td>
              <td>{{ payment.reference }}</td>
              <td>{{ payment.date }}</td>
              <td>
                <router-link :to="{ name: 'admin.payments.show', params: { id: payment.id }}"><i class="lni lni-eye me-2"></i>Details</router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination v-model="pagination.page" :records="pagination.records" @paginate="paginate" :perPage="10" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      payments: [],
      loading: true,
      pagination: { page: 1, records: 1 },
      filter: { sort: 'latest', from: null, to: null, term: '', method: 'all' }
    }
  },

  methods: {
    fetch() {
      this.loading = true
      let url = `/api/v1/admin/payments?search=${this.filter.term}&page=${this.pagination.page}&sort=${this.filter.sort}&method=${this.filter.method}`;

      if(this.filter.from) {
        url += `&date_from=${moment(this.filter.from).format('YYYY-MM-DD')}`
      }

      if(this.filter.to) {
        url += `&date_to=${moment(this.filter.to).format('YYYY-MM-DD')}`
      }

      this.axios.get(url).then(response => {
        this.payments = response.data.payments
        this.pagination = response.data.pagination
        this.loading = false
      })
    }
  }
}
</script>